@charset "UTF-8";
@import "core/brackpoint";
@include mq-pc{
}
@include mq-sp{
}


//2022.08.24 add　課題215
#kv {
  @include mq-sp {
    margin-top: 50px !important;
  };
}

.menu_over_link .header-insta img {
  width: 22px;
  height: auto;
}

.menu_under_link.sp {
  li:after {
    border: none;
    width: 20px;
    height: 20px;
    display: block;
    background: url(../../img/common/header_icon_insta.svg) no-repeat center center / contain;
    transform: none;
    margin-bottom: -11px
  }
}

footer .totop.fix > ul.sp, footer .totop > ul.sp {
  @include mq-sp {
    height: 60px !important;
    padding: 0 !important;
  };
  @include mq-pc {
    display: none !important;
  };
}

footer .totop .footer-icons {
  display: flex !important;
  align-items: center;
  li {
    flex: 1;
    & + li {
      border-left: solid 1px rgba(#fff, 0.2);
    }
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      img {
        width: 22px !important;
        height: auto !important;
        margin-bottom: 5px;
      }
      span {
        font-size: 1rem;
      }
    }
  }
}

#insta-banner {
  text-align: center;
  @include mq-pc{
    padding-bottom: 7rem;
  };
  @include mq-sp{
    padding: 0 5% 5rem 5%;
  };
}



//2024.06.12 add
#insta-block {
  padding-bottom: 80px;
  h2 {
    max-width: 240px;
    width: 100%;
    margin: 0 auto 30px auto;
    @include mq-sp {
      max-width: 150px;
    };
  }
  p {
    text-align: center;
  }
  a {
    display: block;
    max-width: 580px;
    margin: 30px auto 0 auto;
    padding: 10px;
    background-color: #fff;
    @include mq-sp {
      max-width: 90%;
    };
  }
}